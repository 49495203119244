import { CatalogsService } from '@/common/services/swagger/CatalogsService';
import { CatalogsJobsService } from '@/common/services/swagger/CatalogsJobsService';
import { ClassificationsService } from '@/common/services/swagger/ClassificationsService';

import { ImagesService } from '@/common/services/swagger/ImagesService';
import {
  AppSettings,
  AutocompleteSearchResult,
  FilterSettings,
  FilterValueSearchResult,
  ImageSearchResult,
  PrmRelationshipTypeEnum,
  serviceOptions,
  SortDirectionEnum,
  TranslationItem,
  ClassificationNodesSearchResult,
  CatalogAppSettings,
  CatalogSearchTypeEnum,
  CatalogSortFieldEnum,
  CatalogProductSearchTypeEnum,
  CatalogProductSortFieldEnum,
  CatalogProductSearchDataTypeEnum,
  CatalogActionResult,
  CatalogExportPriceSettings,
  JobSearchResult,
  CatalogAutocompleteSearchResult,
  JobSortSettings,
  CatalogProductUpdateItem,
  CatalogProductDeleteItem,
  CatalogSearchResult,
  CatalogOptionsResult,
  CountSearchResult,
  CatalogProductItem,
  CatalogItem,
  CatalogTypeEnum,
  ConfigurationItem,
  QuantityItem,
  AliasAddItem,
  AliasDeleteItem,
  ActionResult,
  AliasActionResult,
  ExportActionResult,
  CatalogExportActionResult,
} from '@/common/services/swagger/index.defs';
import { ProductsService } from '@/common/services/swagger/ProductsService';
import axios from 'axios';
import { reactive } from 'vue';
import { DataType } from '../configuration/application/DataType';
import { Instance } from '../Instance';
import { IErrorEvent, ILoadEvent } from './ILoadEvent';
import { IRouteData } from './IRouteData';
import { StoreService } from './StoreService';
import { CatalogsProductsService } from '@/common/services/swagger/CatalogsProductsService';
import { IHttpService } from '@/common/api/runtime/IHttpService';
import { InstanceType } from '@/common/api/runtime/IInstance';
import { CatalogsAliasesService } from '@/common/services/swagger/CatalogsAliasesService';
import { NotificationType } from '@/common/api/runtime/INotification';
import { TranslationsService } from '@/common/services/swagger/TranslationsService';
export interface PrmLoadArgs {
  productId: string;
  relationshipType: PrmRelationshipTypeEnum;
  page: number;
  pageSize: number;
  filter?: string;
}
export const SESSION_TRANSLATIONS_KEY = 'pis-catalogs-translations';

export class HttpService implements IHttpService {
  instanceType: InstanceType = 'Catalogs';
  private storeService: StoreService;
  private axiosInstance;
  constructor(private instance: Instance, baseURL?: string, accessToken?: string) {
    this.axiosInstance = axios.create({
      baseURL,
      timeout: 60000,
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (config && config.headers) {
          const currentAccessToken = instance.store?.options?.accessToken;
          config.headers['Authorization'] = `Bearer ${currentAccessToken || accessToken}`;

          if (window['PIS'] && window['PIS']['version']) {
            config.headers['Component-Version'] = window['PIS']['version'];
          }

          if (instance.props['dashboard'] === true) {
            config.headers['dashboard'] = 'true';
          }
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.message == 'canceled') {
          return Promise.reject(error);
        }

        const errorData = error?.response?.data;

        const errorEvent: IErrorEvent = {
          type: 'ServerError',
          message: error.message,
          isHandled: false,
        };

        if (errorData) {
          errorEvent.message = errorData.Message;
          errorEvent.type = errorData.Type;
          errorEvent.traceId = errorData.TraceId;
        }

        if (instance.store.options.errorInterceptor) {
          const result = instance.store.options.errorInterceptor(errorEvent);
          if (!result.isHandled) {
            instance.store.notifications.push({
              type: NotificationType.danger,
              message: result.message || 'Unknown error',
            });
          }
        } else {
          instance.store.notifications.push({
            type: NotificationType.danger,
            message: errorEvent.message || 'Unknown error',
          });
        }

        return Promise.reject(error);
      },
    );

    serviceOptions.axios = this.axiosInstance;

    this.storeService = new StoreService(instance);
  }

  private setAxiosInstance() {
    serviceOptions.axios = this.axiosInstance;
  }

  public async load(newRoute?: IRouteData, paginate?: boolean): Promise<void> {
    this.setAxiosInstance();
    if (!this.instance.router) return;

    const route = newRoute || this.instance.router.routeData;
    switch (route.view) {
      case 'catalogs':
        return await this.queryCatalogs(route);
      case 'products':
        return await this.queryProducts(route, paginate, true);
      case 'aliases':
        return await this.queryProducts(route, paginate);

      default:
        throw new Error(`Unknown view type "${route.view}" to be handled by HttpService`);
    }
  }

  public async queryCatalogs({ catalogs }: IRouteData): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;
    try {
      const result = await CatalogsService.search({
        body: {
          appSettings: this.instance.store?.options.application as CatalogAppSettings,
          pager: {
            page: catalogs.pagination.page,
            pageSize: catalogs.pagination.pageSize,
          },
          search: {
            searchText: catalogs.searchText ?? undefined,
            searchType: catalogs.searchType ?? CatalogSearchTypeEnum.CatalogCodeAndDescription,
            createdWithinLastDays: catalogs.createdWithinLastDays,
          },
          sort: {
            field: catalogs.sortField ?? CatalogSortFieldEnum.LastModified,
            direction: catalogs.sortDirection ?? SortDirectionEnum.Descending,
          },
        },
      });

      this.instance.eventBus.emit('search-loaded', result);

      if (result) {
        this.storeService.setData(DataType.Catalogs, result);
      }

      this.instance.store.data.isLoadingData = false;
      this.instance.eventBus.emit('search-changed');
    } catch (e) {
      this.instance.store.data.isLoadingData = false;
      throw e;
    }
  }

  public async queryProducts(
    { products }: IRouteData,
    paginate?: boolean,
    loadProductFilters?: boolean,
  ): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;

    const dataTypes = paginate
      ? [CatalogProductSearchDataTypeEnum.Products]
      : [
        CatalogProductSearchDataTypeEnum.Products,
        CatalogProductSearchDataTypeEnum.Breadcrumbs,
        CatalogProductSearchDataTypeEnum.ProductClassifications,
        CatalogProductSearchDataTypeEnum.PartClassifications,
      ];
    if (!paginate && loadProductFilters === true) {
      dataTypes.push(CatalogProductSearchDataTypeEnum.Filters);
    }

    try {
      const result = await CatalogsProductsService.search({
        body: {
          appSettings: this.instance.store?.options.application as CatalogAppSettings,
          pager: {
            page: products.pagination.page,
            pageSize: products.pagination.pageSize,
          },
          search: {
            searchText: products.searchText ?? undefined,
            searchType: products.searchType ?? CatalogProductSearchTypeEnum.WithAllWords,
            cid: products.cid,
            treeType: products.treeType,
            catalogId: products.catalogId,
            filterSettings: products.filters,
            aliases: products.aliases,
          },
          sort: {
            field: products.sortField ?? CatalogProductSortFieldEnum.LastImport, //   // field: sortField ?? SortFieldEnum.Product,
            direction: products.sortDirection ?? SortDirectionEnum.Ascending,
          },
          dataTypes,
        },
      });

      this.instance.eventBus.emit('search-loaded', result);

      if (result.products) {
        this.storeService.setData(DataType.Products, result.products);
      }

      if (!paginate) {
        if (result.productClassifications) {
          this.storeService.setData(DataType.ProductClassifications, result.productClassifications);
        }

        if (result.partClassifications) {
          this.storeService.setData(DataType.PartClassifications, result.partClassifications);
        }

        if (result.filters) {
          this.storeService.setData(DataType.Filters, result.filters);
        }

        if (result.breadcrumbs) {
          this.storeService.setData(DataType.Breadcrumbs, result.breadcrumbs);
        }
      }

      this.instance.store.data.isLoadingData = false;
      this.instance.eventBus.emit('search-changed');
    } catch (e) {
      this.instance.store.data.isLoadingData = false;
      throw e;
    }
  }

  public async preview(productId: string): Promise<void> {
    this.setAxiosInstance();
    this.instance.store.data.isLoadingData = true;
    try {
      const result = await ProductsService.preview({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          search: {
            productId,
            cid: this.instance.getRouteData().products?.cid,
            filterSettings: this.instance.getRouteData().products?.filters,
          },
        },
      });
      this.instance.eventBus.emit('preview-loaded', result);
      if (result) {
        this.storeService.setData(DataType.ProductPreview, result);
      }

      this.instance.store.data.isLoadingData = false;
    } catch (e) {
      this.instance.store.data.isLoadingData = false;
      throw e;
    }
  }

  public async filterValues(
    attributeCodes: string[],
    catalogId?: string,
  ): Promise<FilterValueSearchResult> {
    this.setAxiosInstance();
    const { products } = this.instance.router.routeData;

    return await CatalogsProductsService.filters({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,

        search: {
          cid: products.cid,
          treeType: products.treeType,
          searchText: products.searchText,
          searchType: products.searchType || CatalogProductSearchTypeEnum.WithAllWords, // TODO: searchType as SearchTypeEnum,
          attributeCodes,
          filterSettings: products.filters,
          catalogId: catalogId,
        },
      },
    });
  }

  public async loadImages(products: string[]): Promise<ImageSearchResult | undefined> {
    this.setAxiosInstance();
    try {
      return await ImagesService.getByProductIds({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          search: {
            products,
          },
        },
      });
    } catch (exception) {
      this.instance.logger.log('Failed to fetch images', exception);
    }
  }

  public async loadImagesById(imageIds: string[]): Promise<ImageSearchResult | undefined> {
    this.setAxiosInstance();
    try {
      return await ImagesService.getByImageIds({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          search: {
            imageIds,
          },
        },
      });
    } catch (exception) {
      this.instance.logger.log('Failed to fetch images', exception);
    }
  }

  public async loadTranslations(): Promise<TranslationItem[] | undefined> {
    this.setAxiosInstance();
    const useCache = this.instance.store.options.cacheTranslations;
    const lang = this.instance.store.options.application?.langCode ?? 'en';
    const key = SESSION_TRANSLATIONS_KEY + '-' + lang;

    let data: TranslationItem[] | undefined;

    if (useCache) {
      try {
        const json = window.sessionStorage.getItem(key);
        if (json) {
          data = JSON.parse(json);
        }
      } catch (error) {
        // ignore
      }
    }

    if (!data) {
      data = await TranslationsService.translations({
        body: {
          appSettings: this.instance.store?.options.application as AppSettings,
          components: [],
        },
      });

      if (useCache) {
        try {
          window.sessionStorage.setItem(key, JSON.stringify(data));
        } catch (error) {
          // ignore
        }
      }
    }

    const interceptFunc = this.instance.store?.options.dataInterceptor;

    const loadEvent: ILoadEvent = {
      type: DataType.Translations,
      data: reactive({ items: data }),
      instance: this.instance,
    };

    if (interceptFunc) {
      const inc = await interceptFunc(loadEvent);
      return inc.data.items;
    } else {
      return data;
    }
  }

  public async autocomplete(searchText: string): Promise<AutocompleteSearchResult> {
    this.setAxiosInstance();
    const { products } = this.instance.router.routeData;

    const result = await CatalogsProductsService.autocomplete({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        pager: {
          page: 1,
          pageSize: 5,
        },
        search: {
          cid: products.cid,
          aliases: products.aliases,
          treeType: products.treeType,
          searchType: products.searchType || CatalogProductSearchTypeEnum.WithAllWords,
          searchText: searchText ?? undefined,
          filterSettings: products.filters,
          catalogId: products.catalogId,
        },
      },
    });

    return result;
  }

  public async autocompleteCatalogs(searchText: string): Promise<CatalogAutocompleteSearchResult> {
    this.setAxiosInstance();
    const { catalogs } = this.instance.router.routeData;

    const result = await CatalogsService.autocomplete({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        pager: {
          page: 1,
          pageSize: 5,
        },
        search: {
          searchType: catalogs.searchType || CatalogSearchTypeEnum.CatalogCodeAndDescription,
          searchText: searchText ?? undefined,
        },
      },
    });

    return result;
  }

  private _lastFilterLoad = {
    key: '',
    count: 0,
  };

  public async loadFiltersResultCount(
    filterSettings: FilterSettings[],
  ): Promise<CountSearchResult> {
    this.setAxiosInstance();
    const { products } = this.instance.router.routeData;

    const params = {
      appSettings: this.instance.store?.options.application as AppSettings,

      search: {
        cid: products.cid,
        treeType: products.treeType,
        searchType: products.searchType || CatalogProductSearchTypeEnum.WithAllWords,
        searchText: products.searchText,
        filterSettings,
        catalogId: products.catalogId,
      },
    };

    const paramsKey = JSON.stringify(params);

    if (this._lastFilterLoad.key == paramsKey) {
      return Promise.resolve({ totalCount: this._lastFilterLoad.count });
    }

    const result = await CatalogsProductsService.count({
      body: params,
    });

    this._lastFilterLoad.key = paramsKey;
    this._lastFilterLoad.count = result.totalCount;

    return result;
  }

  public async loadClassificationsNodes(cids: string[]): Promise<ClassificationNodesSearchResult> {
    this.setAxiosInstance();
    const result = await ClassificationsService.nodes({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        search: {
          cids,
        },
      },
    });

    return result;
  }

  public async exportCatalog(
    catalogId: string,
    priceSettings?: CatalogExportPriceSettings,
  ): Promise<CatalogExportActionResult> {
    this.setAxiosInstance();
    return CatalogsService.export({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        catalogId,
        priceSettings,
      },
    });
  }

  public async downloadCatalog(guid: string): Promise<unknown> {
    this.setAxiosInstance();
    return CatalogsService.download({
      guid,
    });
  }

  public async importCatalog(
    catalogId: string,
    fileContentBase64: string,
    fileName: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsService.import({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        catalogId,
        fileContentBase64,
        fileName,
      },
    });
  }

  public async copyCatalog(
    sourceCatalogId: string,
    targetCatalogId: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsService.copy({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        sourceCatalogId,
        targetCatalogId,
      },
    });
  }

  public async addCatalog(
    code: string,
    type: CatalogTypeEnum,
    description: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsService.add({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        description,
        code,
        type,
      },
    });
  }

  public async updateCatalog(
    catalogId: string,
    description: string,
    isDefault?: boolean,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    const result = await CatalogsService.update({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        catalogId,
        description,
        default: isDefault,
      },
    });
    const newCatalogs: CatalogItem[] = this.instance.store.data.catalogs?.items || [];
    const catalogForUpdate: CatalogItem | undefined = newCatalogs.find(
      (catalog: CatalogItem) => catalog.id === catalogId,
    );
    if (catalogForUpdate) {
      catalogForUpdate.description = description;
      if (isDefault === true || isDefault === false) {
        catalogForUpdate.default = isDefault;
      }
    }
    this.storeService.setData(DataType.Catalogs, {
      items: newCatalogs,
      totalCount: newCatalogs.length,
    });
    return result;
  }

  public async getCatalogOptions(): Promise<CatalogOptionsResult> {
    this.setAxiosInstance();
    return CatalogsService.options({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
      },
    });
  }

  public async updateDefaultCatalog(
    catalogId: string,
    isDefault: boolean,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsService.update({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        catalogId,
        default: isDefault,
      },
    });
  }

  public async deleteCatalog(catalogId: string): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    const result = await CatalogsService.delete({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        catalogId,
      },
    });
    const newCatalogs: CatalogItem[] = (this.instance.store.data.catalogs?.items || []).filter(
      (item: CatalogItem) => item.id !== catalogId,
    );
    this.storeService.setData(DataType.Catalogs, {
      items: newCatalogs,
      totalCount: newCatalogs.length,
    });
    return result;
  }

  public async downloadJobHistory(sortSettings?: JobSortSettings): Promise<JobSearchResult> {
    this.setAxiosInstance();
    return CatalogsJobsService.search({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        sort: sortSettings || undefined,
      },
    });
  }

  public async exportJobHistory(jobId: string): Promise<ExportActionResult> {
    this.setAxiosInstance();
    return CatalogsJobsService.export({
      body: {
        jobId,
        appSettings: this.instance.store?.options.application as AppSettings,
      },
    });
  }

  public async cancelJob(jobId: string): Promise<ActionResult> {
    this.setAxiosInstance();
    return CatalogsJobsService.cancel({
      body: {
        jobId,
        appSettings: this.instance.store?.options.application as AppSettings,
      },
    });
  }

  public async getConfigurationOption(id: string, catalogId: string): Promise<ConfigurationItem> {
    this.setAxiosInstance();
    return await CatalogsProductsService.getConfiguration({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        id,
        catalogId,
      },
    });
  }

  public async importCatalogAliases(
    fileContentBase64: string,
    fileName: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsAliasesService.import({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        fileContentBase64,
        fileName,
      },
    });
  }

  public async exportCatalogAliases(): Promise<ExportActionResult> {
    this.setAxiosInstance();
    return CatalogsAliasesService.export({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
      },
    });
  }

  public async updateCatalogProduct(
    product: CatalogProductUpdateItem,
    catalogId: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    const result = await CatalogsProductsService.update({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        products: [product],
        catalogId,
      },
    });
    const totalCount = this.instance.store.data.products?.totalCount;
    const newCatalogProducts = [...(this.instance.store.data.products?.items || [])].reduce(
      (result: CatalogProductItem[], catalogProduct: CatalogProductItem) => {
        if (catalogProduct.productId === product.productId) {
          const productDescriptionAttribute = Object.values(catalogProduct.attributes).find(
            (attribute) => attribute.attributeCode === '#Description',
          );
          if (productDescriptionAttribute) {
            productDescriptionAttribute.values = [{ text: product.description || '' }];
          }
          Object.assign(catalogProduct, {
            customerClassification: product.customerClassification,
            baseProductId: product.baseProductId,
            customFields: product.customFields,
          });
          catalogProduct.catalogs?.forEach((catalog) => {
            if (catalog.id === catalogId) {
              catalog.quantities?.forEach((quantity: QuantityItem) => {
                if (quantity.unitOfMeasure === product.unitOfMeasure) {
                  quantity.value = product.quantity;
                }
              });
            }
          });
        }
        return result.concat([catalogProduct]);
      },
      [],
    );
    this.storeService.setData(DataType.Products, {
      totalCount,
      items: newCatalogProducts,
    });
    return result;
  }

  public async deleteCatalogProduct(
    products: CatalogProductDeleteItem[],
    catalogId: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    const result = await CatalogsProductsService.delete({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        catalogId,
        products,
      },
    });
    const storeCatalogs = this.instance.store.data.catalogs;
    const storeCatalogProducts = this.instance.store.data.products;
    const newCatalogProducts: CatalogProductItem[] =
      storeCatalogProducts?.items?.filter((product: CatalogProductItem) => {
        const productId: string = product.productId;
        const catalog = product.catalogs?.find((item) => item.id === catalogId);
        if (!catalog) {
          return true;
        }
        const uoms: string[] | undefined = catalog.quantities?.map((item) => item.unitOfMeasure);
        if (!uoms) {
          return true;
        }
        return !products.find(
          (productsForDelete: CatalogProductDeleteItem) =>
            productsForDelete.productId === productId,
        );
      }) || [];
    const newCatalogs: CatalogItem[] = storeCatalogs?.items || [];
    const catalogForUpdate: CatalogItem | undefined = newCatalogs.find(
      (catalog: CatalogItem) => catalog.id === catalogId,
    );
    if (catalogForUpdate) {
      catalogForUpdate.productsTotalCount = Math.abs(
        catalogForUpdate.productsTotalCount - products.length,
      );
    }
    const catalogProductsRemoved = Math.abs(
      (storeCatalogProducts?.items?.length || 0) - newCatalogProducts.length,
    );
    this.storeService.setData(DataType.Catalogs, {
      items: newCatalogs,
      totalCount: storeCatalogs?.totalCount || 0,
    });
    this.storeService.setData(DataType.Products, {
      items: newCatalogProducts,
      totalCount: (storeCatalogProducts?.totalCount || 0) - catalogProductsRemoved,
    });
    return result;
  }

  public async copyCatalogProduct(
    sourceCatalogId: string,
    sourceQuantityIds: string[],
    targetCatalogId: string,
  ): Promise<CatalogActionResult> {
    this.setAxiosInstance();
    return CatalogsProductsService.copyQuantity({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        sourceCatalogId,
        sourceQuantityIds,
        targetCatalogId,
      },
    });
  }

  public async getAllCatalogs(): Promise<CatalogSearchResult> {
    this.setAxiosInstance();
    return CatalogsService.search({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        /** @todo Remove backend limit */
        pager: { page: 1, pageSize: 1000 },
      },
    });
  }

  public async getCatalogById(catalogId: string): Promise<CatalogItem> {
    this.setAxiosInstance();

    return CatalogsService.get({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        catalogId,
      },
    });
  }

  public async addAliases(aliases: AliasAddItem[]): Promise<AliasActionResult> {
    this.setAxiosInstance();

    return CatalogsAliasesService.add({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        aliases,
      },
    });
  }

  public async deleteAliases(aliases: AliasDeleteItem[]): Promise<ActionResult> {
    this.setAxiosInstance();

    return CatalogsAliasesService.delete({
      body: {
        appSettings: this.instance.store?.options.application as AppSettings,
        aliases,
      },
    });
  }
}
