
import { computed, defineComponent } from 'vue';
import Alert from '@/common/components/Alert.vue';
import { IInstance } from '../api/runtime/IInstance';
import { Instance as CatalogsInstance } from '@/catalogs/api/Instance';
import { Instance as ProductsInstance } from '@/products/api/Instance';
import { INotification } from '../api/runtime/INotification';

export default defineComponent({
  components: {
    Alert,
  },
  props: {
    instance: { type: Object as () => IInstance, required: true },
  },
  setup(props) {
    const notifications = computed(() => {
      if (props.instance.instanceType == 'Catalogs') {
        return (props.instance as ProductsInstance).store.notifications;
      }

      if (props.instance.instanceType == 'Products') {
        return (props.instance as CatalogsInstance).store.notifications;
      }
      return [];
    });

    const onClosed = (notification: INotification) => {
      let notifications: INotification[] | undefined = undefined;
      if (props.instance.instanceType == 'Catalogs') {
        notifications = (props.instance as ProductsInstance).store.notifications;
      }

      if (props.instance.instanceType == 'Products') {
        notifications = (props.instance as CatalogsInstance).store.notifications;
      }

      if (notifications && notifications.includes(notification)) {
        notifications.splice(notifications.indexOf(notification), 1);
      }
    };

    return {
      notifications,
      onClosed,
    };
  },
});
