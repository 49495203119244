import { AliasesEnum } from './../../../common/services/swagger/index.defs';
import {
  CatalogProductSearchTypeEnum,
  CatalogProductSortFieldEnum,
  CatalogSearchTypeEnum,
  CatalogSortFieldEnum,
  FilterSettings,
  SortDirectionEnum,
  TreeTypeEnum,
} from '@/common/services/swagger/index.defs';

export type ViewType = 'catalogs' | 'products' | 'aliases';

export enum DisplayMode {
  table = 'table',
  grid = 'grid',
  list = 'list',
}

export interface Pagination {
  page: number;
  pageSize: number;
}

export interface ICatalogsRouteData {
  searchText?: string;
  searchType?: CatalogSearchTypeEnum;
  pagination: Pagination;
  sortDirection?: SortDirectionEnum;
  sortField?: CatalogSortFieldEnum;
  createdWithinLastDays?: number;
}

export interface IProductsRouteData {
  searchText?: string;
  searchType?: CatalogProductSearchTypeEnum;
  cid?: string;
  treeType?: TreeTypeEnum;
  filters?: FilterSettings[];
  pagination: Pagination;
  sortField?: CatalogProductSortFieldEnum;
  sortDirection?: SortDirectionEnum;
  displayMode: DisplayMode;
  catalogId?: string;
  aliases?: AliasesEnum;
}

export interface IRouteData {
  view: ViewType;
  catalogs: ICatalogsRouteData;
  products: IProductsRouteData;
}
