import { Ref, ref, onMounted, computed, ComputedRef, getCurrentInstance, nextTick } from 'vue';
import { useResizeObserver } from '@vueuse/core';

import PIS from '@/common/api/PIS';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { Instance } from '@/catalogs/api/Instance';
import { IStore } from '@/catalogs/api/runtime/IStore';
import { IRouteData } from '@/catalogs/api/runtime/IRouteData';
import { FiltersStorageData } from '@/common/composables/FiltersStorageData';
import { setupFiltersStorage } from '@/common/composables/filtersStorage';
import _debounce from 'lodash/debounce';
import { TranslateFunc as CommonTranslateFunc } from '@/common/composables/translateFunc';

export type TranslateFunc = CommonTranslateFunc;
export type EmitFunc = (name: string, data: unknown) => void;

export type ComponentSetupItems = {
  componentName: string;
  isWebComponent: boolean;
  instance: Ref<Instance | undefined>;
  store: Ref<IStore | undefined>;
  routeData: Ref<IRouteData | undefined>;
  isReady: ComputedRef<boolean>;
  translationsReady: ComputedRef<boolean>;
  shadowRoot: Ref<ShadowRoot | undefined>;
  widerThan: (breakpoint: string) => boolean;
  narrowerThan: (breakpoint: string) => boolean;
  t: TranslateFunc;
  emit: EmitFunc;
  useProductsFilters: () => FiltersStorageData;
};

export const breakpoints = {
  xs: 320,
  sm: 460,
  md: 680,
  lg: 800,
  xl: 1024,
};

export function setupComponent(
  root: Ref<HTMLElement | undefined>,
  instanceId?: string,
  dataTypes?: DataType[],
): ComponentSetupItems {
  const component = getCurrentInstance();
  const componentName = component?.type.name as ComponentName;
  const isWebComponent = component?.isCE ?? false;

  const instance = ref<Instance>();
  const store = ref<IStore>();
  const routeData = ref<IRouteData>();
  const width = ref(0);
  const shadowRoot = ref<ShadowRoot>();

  const parseInstance = (inst?: Instance) => {
    if (inst) {
      instance.value = inst;
      store.value = inst.store;
      routeData.value = inst.router.routeData;

      inst.registerComponent(componentName);

      if (dataTypes) {
        inst.registerRequiredDataTypes(dataTypes);
      }
    }
  };

  if (instanceId) {
    parseInstance(PIS.Catalogs.getInstance(instanceId) as Instance);
  }

  onMounted(() => {
    if (root?.value) {
      const shadow = root.value.getRootNode() as Document | ShadowRoot; // #shadow-root or #document

      if (shadow && shadow !== document) {
        // If the instanceId wasn't passed directly to the component
        // we need to try to find the instance component and get the instance.
        if (!instanceId) {
          const instanceNode = (shadow as ShadowRoot).host.closest(ComponentName.instance) as any;
          shadowRoot.value = shadow as ShadowRoot;

          if (instanceNode?._instance) {
            parseInstance(instanceNode._instance.proxy.getInstance());
          } else {
            // In case of missing access to the `_instance` we are trying to add delay
            // to be sure that the DOM is ready.
            nextTick(() => parseInstance(instanceNode?._instance?.proxy?.getInstance()));
          }
        }
      }
    }
  });

  useResizeObserver(
    root,
    _debounce((entries) => {
      const entry = entries[0];
      width.value = entry.contentRect.width;
    }, 25),
  );

  const widerThan = (breakpoint: string) => width.value > breakpoints[breakpoint];
  const narrowerThan = (breakpoint: string) => width.value <= breakpoints[breakpoint];

  const isReady = computed(
    () => (store.value?.data.isInitialized && store.value?.data.translationsReady) ?? false,
  );
  const translationsReady = computed(() => store.value?.data.translationsReady ?? false);

  const t: TranslateFunc = (slug, useCommon = false, inserts?: string[] | number[]) => {
    const namespace = useCommon ? 'common' : 'catalogs';

    const tr = getTranslation(slug, namespace, inserts);

    if (tr) {
      return tr;
    }

    const trFallback = getTranslation(slug, 'products', inserts);

    return trFallback ?? `{${namespace}:${slug}}`;
  };

  const getTranslation = (
    slug: string,
    namespace: string,
    inserts?: string[] | number[],
  ): string | undefined => {
    const translations = instance.value?.translations.translations[namespace];

    if (translations && translations[slug]) {
      let str = translations[slug];

      if (inserts?.length) {
        inserts.forEach((src, index) => {
          str = str.replace(`{${index}}`, src || '');
        });
      }

      return str;
    }
    return undefined;
  };

  const emit: EmitFunc = (name, data) => {
    instance.value?.eventBus.emit(name, data);
  };

  const useProductsFilters = setupFiltersStorage(instance);

  return {
    componentName,
    isWebComponent,
    instance,
    store,
    routeData,
    isReady,
    translationsReady,
    shadowRoot,
    widerThan,
    narrowerThan,
    t,
    emit,
    useProductsFilters,
  };
}
